<template>
	<div class="wrape">
		<div class="QRCode-wrap">
			<div class="QRCode">
				<h2>
					QR
					<span>Code</span>
				</h2>
				<!--        <div class="content">-->
				<!--          <p>本網站僅適用於「現場點餐」</p>-->
				<!--        </div>-->
				<div class="scanning">
					<span></span>
					<span></span>
					<span></span>
					<span></span>
					<qrcode
						class="qrcode-img"
						:value="`${originHref}/${this.$route.params.random}/Store?openExternalBrowser=1`"
						tag="img"
					></qrcode>
				</div>
			</div>
		</div>
		<ByFooter isWhite />
	</div>
</template>

<script>
import Loader from '@/components/Loader'

export default {
	name: 'QRCode',
	data() {
		return {
			qrCodeHash: '',
		}
	},
	components: {
		Loader,
	},
	computed: {
		originHref() {
			return window.location.origin
		},
	},
	created() {
		const { qrCodeHash } = this.$route.query
		if (qrCodeHash) {
			this.qrCodeHash = qrCodeHash
		}
	},
	methods: {
		getStoreContractStatus() {
			return this.$api.qrCode
				.getStore({
					qrCodeHash: this.qrCodeHash,
				})
				.then(async res => {
					await this.$api.qrCode
						.getStoreContractStatus({
							id: res.data.storeId,
						})
						.then(res => {
							if (!res.data.status) {
								this.$router.push('/Disappears')
							}
						})
						.catch(err => {
							this.$alert(err, 'danger')
						})
				})
				.catch(err => {
					this.$alert(err, 'danger')
				})
		},
	},
}
</script>

<style lang="sass" scoped>
.wrape
  +fc
  background-color: #294B70
  width: 100%
  min-height: 100%
  padding: 17.5px 0
  flex-direction: column
  .QRCode-wrap
    display: flex
    align-items: center
    justify-content: center
    flex: 1
    width: 100%
  .QRCode
    +fc
    flex-direction: column
    position: relative
    width: calc(100% - 35px)
    max-width: 340px
    background-color: #fff
    border-radius: 25px
    box-shadow: 0 0 15px #21212177
    padding: 53.5px 0 30px
    margin-bottom: 25px

    h2
      color: #294B70
      font-size: 40px

      > span
        color: #432D2C

    .content
      line-height: 18.5px
      font-size: 16px
      margin: 14px 0 25px 0
      color: #333333

    .scanning
      width: 73.5%
      height: 50%
      max-width: 250px
      position: relative
      display: flex
      justify-content: center
      align-items: center

      > span
        position: absolute
        padding: 10px 10px
        border-style: solid
        border-color: #294B70

      > span:nth-child(1)
        border-width: 3px 0 0 3px
        left: 0
        top: 0

      > span:nth-child(2)
        border-width: 3px 3px 0 0
        right: 0
        top: 0

      > span:nth-child(3)
        border-width: 0 0 3px 3px
        left: 0
        bottom: 0

      > span:nth-child(4)
        border-width: 0 3px 3px 0
        right: 0
        bottom: 0

      > .qrcode-img
        opacity: 1
</style>
